@import url(https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap);
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #0cacd7;
  border-color: #0cacd7; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0a8fb3;
    border-color: #0985a7; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(48, 184, 221, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #0cacd7;
    border-color: #0cacd7; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0985a7;
    border-color: #097c9b; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(48, 184, 221, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #bf1f1f;
  border-color: #bf1f1f; }
  .btn-danger:hover {
    color: #fff;
    background-color: #9e1a1a;
    border-color: #931818; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(201, 65, 65, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #bf1f1f;
    border-color: #bf1f1f; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #931818;
    border-color: #881616; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(201, 65, 65, 0.5); }

.btn-neutral {
  color: #fff;
  background-color: #1f8cbf;
  border-color: #1f8cbf; }
  .btn-neutral:hover {
    color: #fff;
    background-color: #1a749e;
    border-color: #186c93; }
  .btn-neutral:focus, .btn-neutral.focus {
    box-shadow: 0 0 0 0.2rem rgba(65, 157, 201, 0.5); }
  .btn-neutral.disabled, .btn-neutral:disabled {
    color: #fff;
    background-color: #1f8cbf;
    border-color: #1f8cbf; }
  .btn-neutral:not(:disabled):not(.disabled):active, .btn-neutral:not(:disabled):not(.disabled).active,
  .show > .btn-neutral.dropdown-toggle {
    color: #fff;
    background-color: #186c93;
    border-color: #166488; }
    .btn-neutral:not(:disabled):not(.disabled):active:focus, .btn-neutral:not(:disabled):not(.disabled).active:focus,
    .show > .btn-neutral.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(65, 157, 201, 0.5); }

.btn-light {
  color: #212529;
  background-color: white;
  border-color: white; }
  .btn-light:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: white;
    border-color: white; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #4a505c;
  border-color: #4a505c; }
  .btn-dark:hover {
    color: #fff;
    background-color: #393e47;
    border-color: #333740; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(101, 106, 116, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #4a505c;
    border-color: #4a505c; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #333740;
    border-color: #2e3139; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(101, 106, 116, 0.5); }

.btn-outline-primary {
  color: #0cacd7;
  border-color: #0cacd7; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0cacd7;
    border-color: #0cacd7; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(12, 172, 215, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0cacd7;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0cacd7;
    border-color: #0cacd7; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(12, 172, 215, 0.5); }

.btn-outline-danger {
  color: #bf1f1f;
  border-color: #bf1f1f; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #bf1f1f;
    border-color: #bf1f1f; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 31, 31, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #bf1f1f;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #bf1f1f;
    border-color: #bf1f1f; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(191, 31, 31, 0.5); }

.btn-outline-neutral {
  color: #1f8cbf;
  border-color: #1f8cbf; }
  .btn-outline-neutral:hover {
    color: #fff;
    background-color: #1f8cbf;
    border-color: #1f8cbf; }
  .btn-outline-neutral:focus, .btn-outline-neutral.focus {
    box-shadow: 0 0 0 0.2rem rgba(31, 140, 191, 0.5); }
  .btn-outline-neutral.disabled, .btn-outline-neutral:disabled {
    color: #1f8cbf;
    background-color: transparent; }
  .btn-outline-neutral:not(:disabled):not(.disabled):active, .btn-outline-neutral:not(:disabled):not(.disabled).active,
  .show > .btn-outline-neutral.dropdown-toggle {
    color: #fff;
    background-color: #1f8cbf;
    border-color: #1f8cbf; }
    .btn-outline-neutral:not(:disabled):not(.disabled):active:focus, .btn-outline-neutral:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-neutral.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(31, 140, 191, 0.5); }

.btn-outline-light {
  color: white;
  border-color: white; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: white;
    border-color: white; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: white;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: white;
    border-color: white; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-dark {
  color: #4a505c;
  border-color: #4a505c; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #4a505c;
    border-color: #4a505c; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(74, 80, 92, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #4a505c;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #4a505c;
    border-color: #4a505c; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(74, 80, 92, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0cacd7 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0985a7 !important; }

.bg-danger {
  background-color: #bf1f1f !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #931818 !important; }

.bg-neutral {
  background-color: #1f8cbf !important; }

a.bg-neutral:hover, a.bg-neutral:focus,
button.bg-neutral:hover,
button.bg-neutral:focus {
  background-color: #186c93 !important; }

.bg-light {
  background-color: white !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e6e6 !important; }

.bg-dark {
  background-color: #4a505c !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #333740 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0cacd7 !important; }

.border-danger {
  border-color: #bf1f1f !important; }

.border-neutral {
  border-color: #1f8cbf !important; }

.border-light {
  border-color: white !important; }

.border-dark {
  border-color: #4a505c !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #0cacd7 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #08728f !important; }

.text-danger {
  color: #bf1f1f !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #7d1414 !important; }

.text-neutral {
  color: #1f8cbf !important; }

a.text-neutral:hover, a.text-neutral:focus {
  color: #145c7d !important; }

.text-light {
  color: white !important; }

a.text-light:hover, a.text-light:focus {
  color: #d9d9d9 !important; }

.text-dark {
  color: #4a505c !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #282b32 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bbe8f4; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #81d4ea; }

.table-hover .table-primary:hover {
  background-color: #a5e1f0; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a5e1f0; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #edc0c0; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #de8b8b; }

.table-hover .table-danger:hover {
  background-color: #e7acac; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #e7acac; }

.table-neutral,
.table-neutral > th,
.table-neutral > td {
  background-color: #c0dfed; }

.table-neutral th,
.table-neutral td,
.table-neutral thead th,
.table-neutral tbody + tbody {
  border-color: #8bc3de; }

.table-hover .table-neutral:hover {
  background-color: #acd5e7; }
  .table-hover .table-neutral:hover > td,
  .table-hover .table-neutral:hover > th {
    background-color: #acd5e7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: white; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: white; }

.table-hover .table-light:hover {
  background-color: #f2f2f2; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f2f2f2; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #ccced1; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #a1a4aa; }

.table-hover .table-dark:hover {
  background-color: #bfc1c5; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bfc1c5; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }
  .animated.bounceIn {
    -webkit-animation-duration: .7s;
            animation-duration: .7s; }
  .animated.bounceOut {
    -webkit-animation-duration: .5s;
            animation-duration: .5s; }
  .animated.bounceIn {
    -webkit-animation-name: bounceIn;
            animation-name: bounceIn; }
  .animated.bounceOut {
    -webkit-animation-name: bounceOut;
            animation-name: bounceOut; }
  .animated.fadeIn {
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: .7s;
            animation-duration: .7s; }
  .animated.fadeOut {
    -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
    -webkit-animation-duration: .3s;
            animation-duration: .3s; }
  .animated.bounceInDown {
    -webkit-animation-name: bounceInDown;
            animation-name: bounceInDown; }
  .animated.bounceOutUp {
    -webkit-animation-name: bounceOutUp;
            animation-name: bounceOutUp; }

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
            transform: perspective(1px) scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
            transform: perspective(1px) scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
            transform: perspective(1px) scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
            transform: perspective(1px) scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
            transform: perspective(1px) scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1, 1, 1);
            transform: perspective(1px) scale3d(1, 1, 1); } }

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
            transform: perspective(1px) scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
            transform: perspective(1px) scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
            transform: perspective(1px) scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
            transform: perspective(1px) scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
            transform: perspective(1px) scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1, 1, 1);
            transform: perspective(1px) scale3d(1, 1, 1); } }

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.rrt-confirm-holder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999; }
  .rrt-confirm-holder .shadow {
    width: 100%;
    height: 100%;
    background-color: rgba(50, 58, 68, 0.8); }
  .rrt-confirm-holder .rrt-confirm {
    width: 320px;
    background-color: white;
    position: absolute;
    z-index: 9;
    top: 20%;
    left: 50%;
    margin-left: -160px;
    box-shadow: 3px 3px 20px #333;
    border-radius: 4px;
    overflow: hidden; }
    .rrt-confirm-holder .rrt-confirm .rrt-message {
      width: 100%;
      padding: 5%;
      min-height: 50px;
      font-size: 1em;
      background-color: white;
      text-align: center;
      font-family: 'open-sanscondensed-light', sans-serif;
      clear: both; }
    .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder {
      display: flex; }
      .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
        flex-grow: 1;
        height: 50px;
        text-transform: capitalize;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        float: left;
        border-top: 1px solid #f0f0f0;
        font-size: 14px;
        overflow: hidden;
        cursor: pointer; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:hover {
          background-color: #f5f5f5; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-ok-btn:active {
          background-color: #60bb71;
          color: white; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-cancel-btn:active {
          background-color: #db6a64;
          color: white; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:focus {
          outline: none; }

body.toastr-confirm-active {
  overflow: hidden; }

.redux-toastr *, .redux-toastr *:before, .redux-toastr *:after {
  box-sizing: border-box; }

.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  width: 350px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px; }

.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center {
  top: 0; }

.redux-toastr .top-right, .redux-toastr .bottom-right {
  right: 0; }

.redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  bottom: 0; }

.redux-toastr .top-left, .redux-toastr .bottom-left {
  left: 0; }

.redux-toastr .top-center, .redux-toastr .bottom-center {
  left: 50%;
  margin-left: -175px; }

@media (max-width: 320px) {
  .redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
    width: 320px; }
  .redux-toastr .top-center, .redux-toastr .bottom-center {
    margin-left: -160px; } }

.redux-toastr .toastr {
  background-color: #fcfcfc;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  color: #333;
  opacity: .94;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4); }
  .redux-toastr .toastr:hover:not(.rrt-message) {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
    opacity: 1; }
  .redux-toastr .toastr .toastr-status {
    width: 100%;
    height: 5px; }
    .redux-toastr .toastr .toastr-status.success {
      background-color: #60bb71; }
    .redux-toastr .toastr .toastr-status.warning {
      background-color: #f7a336; }
    .redux-toastr .toastr .toastr-status.info {
      background-color: #58abc3; }
    .redux-toastr .toastr .toastr-status.error {
      background-color: #db6a64; }
  .redux-toastr .toastr .rrt-left-container, .redux-toastr .toastr .rrt-right-container {
    float: left;
    text-align: center;
    overflow: hidden; }
  .redux-toastr .toastr .rrt-left-container {
    width: 80px;
    top: 0;
    left: 0;
    position: absolute;
    bottom: 0; }
    .redux-toastr .toastr .rrt-left-container .rrt-holder {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 50%;
      margin-top: -35px;
      left: 5px;
      line-height: 60px; }
    .redux-toastr .toastr .rrt-left-container .toastr-icon {
      fill: white;
      vertical-align: middle;
      margin-top: 5px; }
  .redux-toastr .toastr .rrt-middle-container {
    width: 65%;
    margin-left: 80px;
    position: relative;
    float: left;
    font-family: Arial, Helvetica, sans-serif, sans-serif;
    font-size: 1em;
    text-align: left;
    padding: 10px 5px; }
    .redux-toastr .toastr .rrt-middle-container .rrt-title {
      font-size: 1.1em;
      font-weight: bold;
      margin-bottom: 5px; }
  .redux-toastr .toastr .rrt-right-container {
    width: 10%; }
  .redux-toastr .toastr .close-toastr {
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    font-size: 22px;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
    font-family: "Helvetica Neue", Helvetica, Arial sans-serif;
    color: #000; }
    .redux-toastr .toastr .close-toastr:hover {
      opacity: 1; }
    .redux-toastr .toastr .close-toastr:focus {
      outline: none; }
    .redux-toastr .toastr .close-toastr span {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
  .redux-toastr .toastr.rrt-info, .redux-toastr .toastr.rrt-success, .redux-toastr .toastr.rrt-warning, .redux-toastr .toastr.rrt-error {
    color: white; }
  .redux-toastr .toastr.rrt-info {
    background-color: #58abc3; }
    .redux-toastr .toastr.rrt-info .rrt-progressbar {
      background-color: #378298; }
  .redux-toastr .toastr.rrt-success {
    background-color: #60bb71; }
    .redux-toastr .toastr.rrt-success .rrt-progressbar {
      background-color: #3e914d; }
  .redux-toastr .toastr.rrt-warning {
    background-color: #f7a336; }
    .redux-toastr .toastr.rrt-warning .rrt-progressbar {
      background-color: #d87e09; }
  .redux-toastr .toastr.rrt-error {
    background-color: #db6a64; }
    .redux-toastr .toastr.rrt-error .rrt-progressbar {
      background-color: #c5352e; }
  .redux-toastr .toastr.rrt-light .rrt-progressbar {
    background-color: #ccc; }
  .redux-toastr .toastr.rrt-light .toastr-icon {
    fill: #333 !important; }
  .redux-toastr .toastr.rrt-message {
    opacity: 1;
    border: 1px solid #dbdbdb; }
    .redux-toastr .toastr.rrt-message .rrt-title {
      width: 90%;
      height: 50px;
      text-align: center;
      overflow: hidden;
      font-size: 1.2em;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 50px;
      padding: 0 20px; }
    .redux-toastr .toastr.rrt-message .rrt-text {
      width: 100%;
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto;
      border-top: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
      background-color: white;
      padding: 15px;
      font-size: 1.1em;
      margin-bottom: 20px; }
      .redux-toastr .toastr.rrt-message .rrt-text img {
        display: block;
        margin: 10px auto;
        max-width: 100%; }
    .redux-toastr .toastr.rrt-message .close-toastr {
      height: 50px; }
  .redux-toastr .toastr .rrt-progress-container {
    height: 5px;
    margin: 0 -20px -20px -60px;
    position: absolute;
    bottom: 20px;
    width: 100%; }
    .redux-toastr .toastr .rrt-progress-container .rrt-progressbar {
      border-radius: 0 0 0 4px;
      height: 100%; }

.redux-toastr .toastr-attention {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1; }

a.btn {
  text-decoration: none; }

button, .btn {
  cursor: pointer; }

.btn {
  font-weight: bold; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Nunito Sans,sans-serif;
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: #fff; }

html {
  min-height: 100vh;
  background: #fff;
  font-size: 14px; }

body, html, #root {
  height: 100%;
  line-height: 1.42857;
  font-feature-settings: "tnum","tnum", "tnum";
  font-variant: tabular-nums;
  background-color: transparent;
  --main-accent-color: 12, 172, 215;
  --main-accent-color-dark: 30, 65, 117;
  --main-accent-text-color: 255, 255, 255; }

.theme-light {
  --sidebar-dark-color: 45, 75, 114;
  --hero-dark-alpha: 0.3;
  --bg-color: 215, 241, 255;
  --input-field-bg: 230, 230, 231;
  --input-field-border: 0, 0, 0;
  --spinner-bg: 255, 255, 255;
  --spinner-border: 0, 0, 0;
  --title-color: 12, 172, 215;
  --text-color: 12, 172, 215;
  --form-card-border: 255, 255, 255;
  --card-border: 156, 150, 150;
  --card-color: 255, 255, 255;
  --card-media-bg: 12, 172, 215;
  --card-accent: 255, 255, 255;
  --hero-card-color: 255, 255, 255;
  --hero-card-alpha: .7;
  --error-bg: 95, 168, 211;
  --error-color: 255, 255, 255;
  --header-bg: 255, 255, 255;
  --header-color: 12, 172, 215;
  --pagination-active-bg: 83, 169, 241;
  --pagination-active-color: 0, 0, 0;
  --pagination-active-border: 57, 145, 218;
  --pagination-disabled-bg: 182, 215, 232;
  --pagination-disabled-color: 105, 117, 123;
  --pagination-disabled-border: 173, 211, 232;
  --pagination-bg: 212, 238, 252;
  --pagination-color: 1, 85, 156;
  --pagination-border: 173, 211, 232;
  --pagination-hover-bg: 220, 243, 255;
  --pagination-hover-color: 38, 127, 202;
  --pagination-hover-border: 83, 169, 241;
  --ghostload-color: 233, 233, 233;
  --ghostload-accent: 243, 243, 243;
  --scrollbar-bg: 255, 255, 255;
  --scrollbar-color: 0, 0, 0; }

.theme-dark {
  --sidebar-dark-color: 0, 0, 0;
  --hero-dark-alpha: 0.8;
  --bg-color: 20, 21, 25;
  --input-field-bg: 0, 0, 0;
  --input-field-border: 120, 120, 120;
  --spinner-bg: 21, 19, 18;
  --spinner-border: 0, 0, 0;
  --title-color: 224, 214, 214;
  --text-color: 224, 214, 214;
  --form-card-border: 37, 31, 28;
  --card-border: 44, 50, 62;
  --card-color: 21, 19, 18;
  --card-media-bg: 44, 50, 62;
  --card-accent: 255, 255, 255;
  --hero-card-color: 74, 80, 92;
  --hero-card-alpha: 0.9;
  --error-bg: 35, 35, 35;
  --error-color: 255, 255, 255;
  --header-bg: 24, 24, 24;
  --header-color: 161, 171, 181;
  --pagination-active-bg: 1, 85, 156;
  --pagination-active-color: 255, 255, 255;
  --pagination-active-border: 0, 31, 58;
  --pagination-disabled-bg: 44, 46, 51;
  --pagination-disabled-color: 105, 117, 123;
  --pagination-disabled-border: 37, 33, 32;
  --pagination-bg: 25, 21, 20;
  --pagination-color: 1, 85, 156;
  --pagination-border: 35, 31, 30;
  --pagination-hover-bg: 45, 41, 40;
  --pagination-hover-color: 38, 127, 202;
  --pagination-hover-border: 55, 51, 50;
  --ghostload-color: 62, 50, 44;
  --ghostload-accent: 102, 90, 84;
  --scrollbar-bg: 60, 60, 60;
  --scrollbar-color: 120, 120, 120; }

::-webkit-scrollbar {
  background: RGBA(var(--scrollbar-bg), 0.2);
  position: absolute;
  height: 5px;
  width: 5px; }

::-webkit-scrollbar-thumb {
  background-color: RGBA(var(--scrollbar-color), 0.6); }

::-webkit-scrollbar-thumb:hover {
  background-color: RGBA(var(--scrollbar-color), 0.8); }

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px RGBA(var(--scrollbar-color), 0.1); }

@-webkit-keyframes ghostLoading {
  0% {
    background-position: -50vmin; }
  100% {
    background-position: 100vmin; } }

@keyframes ghostLoading {
  0% {
    background-position: -50vmin; }
  100% {
    background-position: 100vmin; } }

.ghostLoad {
  -webkit-animation: ghostLoading 2.5s infinite linear;
          animation: ghostLoading 2.5s infinite linear;
  background-color: RGB(var(--ghostload-color));
  background-image: -webkit-gradient(linear, left top, right top, from(RGB(var(--ghostload-color))), color-stop(20%, RGB(var(--ghostload-accent))), color-stop(50%, RGB(var(--ghostload-color))), to(RGBA(var(--ghostload-color))));
  background-image: linear-gradient(to right, RGB(var(--ghostload-color)) 0%, RGB(var(--ghostload-accent)) 20%, RGB(var(--ghostload-color)) 50%, RGBA(var(--ghostload-color)) 100%);
  background-repeat: no-repeat; }

img::-moz-selection {
  background-color: transparent;
  color: transparent; }

img::selection {
  background-color: transparent;
  color: transparent; }

.App{
    height: 100%;
	background-color: RGB(var(--bg-color));
	display: block;
	color: RGB(var(--text-color));
}

.app-container{
	display: flex;
    flex-direction: column;
	height: 100%;
	will-change: padding;
	padding-left: 0;
    -webkit-transition: padding .2s ease-in-out;
    transition: padding .2s ease-in-out;
}

.overlay{
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, .15);
	top: 0;
	left: 0;
	display: block;
	z-index: 999;
	position: fixed;
}

*, :after, :before {
	box-sizing: border-box;
}

@media screen and (min-width : 769px){
	.app-container.logged{
		padding-left: 18.57143rem;
	}
}
.form-title {
  color: RGB(var(--title-color));
  font-size: 1.8em;
  letter-spacing: .1px; }

.form-holder {
  position: relative; }

.form-item {
  color: RGB(var(--title-color));
  font-size: 14px;
  line-height: 1.5;
  padding-bottom: 8px;
  position: relative; }
  .form-item:after, .form-item:before {
    display: table;
    content: ""; }
  .form-item > .form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    text-align: left; }
    .form-item > .form-item-label > label {
      color: RGB(var(--title-color));
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0; }
  .form-item > .form-item-error {
    color: #ed253d;
    clear: both;
    min-height: 22px;
    margin-top: -2px;
    font-size: 14px;
    line-height: 1.5; }
  .form-item > .form-item-field {
    position: relative; }
    .form-item > .form-item-field > .form-item-wrapper {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: RGB(var(--title-color));
      font-size: 14px;
      line-height: 1.5;
      list-style: none;
      position: relative;
      width: 100%;
      text-align: start;
      display: inline-block;
      /*input::selection, textarea::selection, select::selection {
				background-color: transparent;
			}*/ }
      .form-item > .form-item-field > .form-item-wrapper > .file-dropper {
        position: relative;
        width: 100%; }
        .form-item > .form-item-field > .form-item-wrapper > .file-dropper > input {
          display: none; }
        .form-item > .form-item-field > .form-item-wrapper > .file-dropper > .file-dropper-thumbnails {
          margin-top: 5px;
          margin-bottom: 5px;
          display: flex;
          flex-direction: row;
          position: relative;
          flex-wrap: wrap;
          width: 100%;
          z-index: 2;
          justify-content: space-between;
          align-items: baseline;
          padding: 5px;
          border-radius: 5px;
          border: 1px dashed RGBA(var(--input-field-border), 0.5);
          background-color: RGBA(var(--input-field-bg), 0.7); }
          .form-item > .form-item-field > .form-item-wrapper > .file-dropper > .file-dropper-thumbnails > .thumbnail {
            flex-basis: 23%;
            margin: 5px 0; }
          .form-item > .form-item-field > .form-item-wrapper > .file-dropper > .file-dropper-thumbnails:hover {
            background-color: RGBA(var(--input-field-bg), 0.9); }
        .form-item > .form-item-field > .form-item-wrapper > .file-dropper > .file-dropper-disclaimers {
          display: flex;
          flex-direction: column;
          padding: 0 10px; }
          .form-item > .form-item-field > .form-item-wrapper > .file-dropper > .file-dropper-disclaimers > .disclaimer {
            font-size: 12px;
            line-height: 12px; }
        .form-item > .form-item-field > .form-item-wrapper > .file-dropper > .file-dropper-placeholder {
          margin-bottom: 5px;
          padding: 5px;
          min-height: 90px;
          border: 1px solid RGBA(var(--input-field-border), 0.3);
          background-color: RGBA(var(--input-field-bg), 0.7);
          cursor: pointer;
          border-radius: 10px;
          position: relative;
          display: flex;
          width: 100%;
          z-index: 1;
          align-items: center;
          justify-content: center;
          top: 0;
          left: 0; }
          .form-item > .form-item-field > .form-item-wrapper > .file-dropper > .file-dropper-placeholder.disabled {
            cursor: not-allowed;
            background-color: #ccc !important; }
          .form-item > .form-item-field > .form-item-wrapper > .file-dropper > .file-dropper-placeholder.required {
            border-color: RGBA(237, 37, 61, 0.5); }
          .form-item > .form-item-field > .form-item-wrapper > .file-dropper > .file-dropper-placeholder:hover {
            background-color: RGBA(var(--input-field-bg), 0.9); }
            .form-item > .form-item-field > .form-item-wrapper > .file-dropper > .file-dropper-placeholder:hover.required {
              border-color: RGBA(237, 37, 61, 0.8); }
          .form-item > .form-item-field > .form-item-wrapper > .file-dropper > .file-dropper-placeholder > .placeholder-text {
            color: RGB(var(--title-color));
            font-size: 16px;
            font-weight: bold; }
      .form-item > .form-item-field > .form-item-wrapper > .switch-wrapper {
        display: flex;
        align-items: center; }
        .form-item > .form-item-field > .form-item-wrapper > .switch-wrapper > .switch-label {
          color: RGB(var(--title-color));
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 0;
          margin-left: 5px;
          cursor: pointer; }
          .form-item > .form-item-field > .form-item-wrapper > .switch-wrapper > .switch-label.switch-label-positive {
            margin-left: 5px; }
          .form-item > .form-item-field > .form-item-wrapper > .switch-wrapper > .switch-label.switch-label-negative {
            margin-right: 5px; }
        .form-item > .form-item-field > .form-item-wrapper > .switch-wrapper > .switch {
          flex-grow: 1;
          background-color: RGBA(var(--input-field-bg), 0.7);
          border: 1px solid RGBA(var(--input-field-border), 0.3);
          height: 20px;
          padding: 0 5px;
          border-radius: 30px;
          /*flex: 0 0 60px;*/
          width: 60px;
          max-width: 60px;
          position: relative;
          -webkit-transition: background-color ease-in-out .1s;
          transition: background-color ease-in-out .1s; }
          .form-item > .form-item-field > .form-item-wrapper > .switch-wrapper > .switch > .switch-indicator {
            height: 25px;
            width: 25px;
            display: block;
            position: absolute;
            background-color: RGB(var(--input-field-bg));
            border: 1px solid RGBA(var(--input-field-border), 0.3);
            border-radius: 50%;
            cursor: pointer;
            top: -4px;
            left: 0px;
            -webkit-transition: left ease-in-out .2s, background-color ease-in-out .1s;
            transition: left ease-in-out .2s, background-color ease-in-out .1s; }
            .form-item > .form-item-field > .form-item-wrapper > .switch-wrapper > .switch > .switch-indicator:hover {
              background-color: RGB(var(--main-accent-color));
              border-color: rgba(var(--input-field-border), 0.5); }
          .form-item > .form-item-field > .form-item-wrapper > .switch-wrapper > .switch.active {
            background-color: RGBA(var(--main-accent-color), 0.6); }
            .form-item > .form-item-field > .form-item-wrapper > .switch-wrapper > .switch.active > .switch-indicator {
              left: calc(100% - 25px);
              background-color: RGB(var(--main-accent-color)); }
        .form-item > .form-item-field > .form-item-wrapper > .switch-wrapper > input[type=hidden]:required:invalid ~ .switch {
          border-color: RGBA(237, 37, 61, 0.5); }
          .form-item > .form-item-field > .form-item-wrapper > .switch-wrapper > input[type=hidden]:required:invalid ~ .switch:hover {
            border-color: RGBA(237, 37, 61, 0.8); }
      .form-item > .form-item-field > .form-item-wrapper > .input-wrapper {
        display: flex; }
        .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > .checkbox-label, .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > .radio-label {
          color: RGB(var(--title-color));
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 0;
          margin-left: 5px;
          cursor: pointer; }
        .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=checkbox] {
          border-radius: 3px; }
          .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=checkbox]:checked:after {
            content: '\2714';
            font-size: 14px;
            color: RGB(var(--main-accent-color));
            top: 0px;
            left: 4px; }
        .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=radio] {
          border-radius: 50%; }
          .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=radio]:checked:after {
            content: ' ';
            height: 11px;
            width: 11px;
            background-color: RGB(var(--main-accent-color));
            display: block;
            border-radius: 50%;
            top: 4px;
            left: 4px; }
        .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=checkbox], .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=radio] {
          -webkit-appearance: none;
             -moz-appearance: none;
                  appearance: none;
          background-color: RGBA(var(--input-field-bg), 0.7);
          border: 1px solid RGBA(var(--input-field-border), 0.3);
          padding: 9px;
          display: inline-block;
          position: relative;
          outline: none;
          height: 23px;
          width: 23px;
          cursor: pointer; }
          .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=checkbox]:required:invalid, .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=radio]:required:invalid {
            border-color: RGBA(237, 37, 61, 0.5); }
          .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=checkbox]:active, .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=checkbox]:checked:active, .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=checkbox]:hover, .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=radio]:active, .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=radio]:checked:active, .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=radio]:hover {
            background-color: RGBA(var(--input-field-bg), 0.9); }
            .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=checkbox]:active:required:invalid, .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=checkbox]:checked:active:required:invalid, .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=checkbox]:hover:required:invalid, .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=radio]:active:required:invalid, .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=radio]:checked:active:required:invalid, .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=radio]:hover:required:invalid {
              border-color: RGBA(237, 37, 61, 0.8); }
          .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=checkbox]:checked, .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=radio]:checked {
            background-color: RGBA(var(--input-field-bg), 0.9);
            border-color: RGB(var(--main-accent-color));
            color: RGB(var(--main-accent-color)); }
            .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=checkbox]:checked:after, .form-item > .form-item-field > .form-item-wrapper > .input-wrapper > input[type=radio]:checked:after {
              position: absolute; }
      .form-item > .form-item-field > .form-item-wrapper > .form-item-prefix, .form-item > .form-item-field > .form-item-wrapper > .form-item-suffix {
        position: absolute;
        top: 50%;
        z-index: 2;
        color: RGB(var(--title-color));
        line-height: 0;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
      .form-item > .form-item-field > .form-item-wrapper > .form-item-prefix {
        left: 12px; }
      .form-item > .form-item-field > .form-item-wrapper > .form-item-suffix {
        right: 12px; }
      .form-item > .form-item-field > .form-item-wrapper > select {
        border-radius: 15px 15px 0 0; }
        .form-item > .form-item-field > .form-item-wrapper > select > option {
          background-color: RGB(var(--input-field-bg));
          border-color: RGBA(var(--input-field-border), 0.3);
          border-style: solid; }
        .form-item > .form-item-field > .form-item-wrapper > select > option:hover,
        .form-item > .form-item-field > .form-item-wrapper > select > option:focus,
        .form-item > .form-item-field > .form-item-wrapper > select > option:active,
        .form-item > .form-item-field > .form-item-wrapper > select > option:checked {
          color: #000;
          font-weight: bold;
          background-color: RGB(var(--main-accent-color)); }
      .form-item > .form-item-field > .form-item-wrapper > span.input {
        display: flex;
        align-content: center;
        justify-items: center;
        line-height: 40px;
        overflow: hidden; }
      .form-item > .form-item-field > .form-item-wrapper > span.input, .form-item > .form-item-field > .form-item-wrapper > textarea, .form-item > .form-item-field > .form-item-wrapper > input:not([type=checkbox]):not([type=radio]), .form-item > .form-item-field > .form-item-wrapper > select {
        box-shadow: none;
        border: 0px solid RGBA(var(--input-field-border), 0.3);
        background-color: RGBA(var(--input-field-bg), 0.7);
        height: 40px;
        color: RGB(var(--title-color));
        padding: 0 30px;
        position: relative;
        text-align: inherit;
        min-height: 100%;
        width: 100%;
        outline: none;
        background-size: 13px;
        background-repeat: no-repeat;
        background-position: calc(100% - 43px) center; }
        .form-item > .form-item-field > .form-item-wrapper > span.input:required:invalid, .form-item > .form-item-field > .form-item-wrapper > textarea:required:invalid, .form-item > .form-item-field > .form-item-wrapper > input:not([type=checkbox]):not([type=radio]):required:invalid, .form-item > .form-item-field > .form-item-wrapper > select:required:invalid {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAECSURBVHja7NZBFYQgEABQG/CIABWoQAUqWIEKVrACFaxgAC5UoAJ7mX2P547DrLi6Bw9zGuQLzKBDKWW4I4YH/ms4CrVEocomlivgcBc8I3C4Ap4QeLoC9gjsT4GjUA7O0iK5EYFH4iVTFEpy4bqA1iiUq3IWgW2VN0gdOC6ckclXQDWSk5DDWu2jBqhtLkSEnZeinlk58NyY5GiYFpx/BHvOGXuIpQPK1TzmcB9DGwUCStx+/ha2jFWOp8JMlI33ooHoANsFN1aqIfK3eA/qGx+Nd1WbI/DebZSQsYlzY3FhudPHlrk7obe4POeDv+lxd1Y7GVi9JMZoGKOf/+oHfg0AbXM/rnFo+PsAAAAASUVORK5CYII=);
          border-color: RGBA(237, 37, 61, 0.5); }
        .form-item > .form-item-field > .form-item-wrapper > span.input:focus, .form-item > .form-item-field > .form-item-wrapper > span.input:hover, .form-item > .form-item-field > .form-item-wrapper > textarea:focus, .form-item > .form-item-field > .form-item-wrapper > textarea:hover, .form-item > .form-item-field > .form-item-wrapper > input:not([type=checkbox]):not([type=radio]):focus, .form-item > .form-item-field > .form-item-wrapper > input:not([type=checkbox]):not([type=radio]):hover, .form-item > .form-item-field > .form-item-wrapper > select:focus, .form-item > .form-item-field > .form-item-wrapper > select:hover {
          outline: none;
          border-color: RGBA(var(--input-field-border), 0.5);
          background-color: RGBA(var(--input-field-bg), 0.9); }
          .form-item > .form-item-field > .form-item-wrapper > span.input:focus:required:invalid, .form-item > .form-item-field > .form-item-wrapper > span.input:hover:required:invalid, .form-item > .form-item-field > .form-item-wrapper > textarea:focus:required:invalid, .form-item > .form-item-field > .form-item-wrapper > textarea:hover:required:invalid, .form-item > .form-item-field > .form-item-wrapper > input:not([type=checkbox]):not([type=radio]):focus:required:invalid, .form-item > .form-item-field > .form-item-wrapper > input:not([type=checkbox]):not([type=radio]):hover:required:invalid, .form-item > .form-item-field > .form-item-wrapper > select:focus:required:invalid, .form-item > .form-item-field > .form-item-wrapper > select:hover:required:invalid {
            border-color: RGBA(255, 0, 0, 0.8); }
      .form-item > .form-item-field > .form-item-wrapper > textarea {
        border-radius: 15px;
        resize: none;
        padding: 10px 30px;
        height: 120px; }
      .form-item > .form-item-field > .form-item-wrapper > span.input, .form-item > .form-item-field > .form-item-wrapper > input:not([type=checkbox]):not([type=radio]) {
        border-radius: 30px; }
      .form-item > .form-item-field > .form-item-wrapper textarea:disabled, .form-item > .form-item-field > .form-item-wrapper input:disabled, .form-item > .form-item-field > .form-item-wrapper select:disabled {
        cursor: not-allowed;
        background-color: #ccc !important; }

.sidebar {
  background-image: linear-gradient(245deg, RGBA(var(--main-accent-color), 0.5), RGB(var(--sidebar-dark-color)) 65%);
  display: flex;
  justify-content: flex-end;
  height: 100%;
  left: 0;
  overflow: auto;
  position: absolute;
  top: 0;
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
  width: 18.57143rem;
  will-change: transform,width;
  -webkit-overflow-scrolling: touch;
  z-index: 1000; }
  @media screen and (max-width: 768px) {
    .sidebar {
      -webkit-transform: translateX(-18.57143rem);
              transform: translateX(-18.57143rem); }
      .sidebar.open {
        -webkit-transform: translateX(0);
                transform: translateX(0); } }
  .sidebar > .navbar-wrap {
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding: 0;
    width: 18.57143rem;
    background: inherit;
    display: flex;
    margin: 0 auto;
    min-height: inherit;
    position: relative; }
    .sidebar > .navbar-wrap > * {
      margin: 0;
      width: 100%; }
    .sidebar > .navbar-wrap > .logo {
      min-height: 60px;
      background: RGB(var(--header-bg));
      box-shadow: 0px 5px 10px 0 RGBA(0, 0, 0, 0.3);
      z-index: 99;
      display: block;
      height: 60px;
      position: relative;
      padding: .71429rem 1.42857rem; }
      .sidebar > .navbar-wrap > .logo > .logo-wrap {
        align-items: center;
        color: inherit;
        display: flex;
        height: 100%;
        text-decoration: none;
        align-items: center;
        justify-content: space-between; }
        .sidebar > .navbar-wrap > .logo > .logo-wrap > a {
          height: 100%; }
        .sidebar > .navbar-wrap > .logo > .logo-wrap > .close {
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          cursor: pointer; }
        .sidebar > .navbar-wrap > .logo > .logo-wrap > .username {
          font-weight: bold;
          color: RGB(var(--header-color)); }
        .sidebar > .navbar-wrap > .logo > .logo-wrap > svg {
          color: RGB(var(--header-color)); }
    .sidebar > .navbar-wrap > .menu {
      display: block;
      letter-spacing: .2px;
      margin: 0;
      max-width: 100%;
      height: 100%;
      padding: 0;
      -webkit-transition: opacity .2s ease-in-out .2s;
      transition: opacity .2s ease-in-out .2s;
      overflow: auto; }
      .sidebar > .navbar-wrap > .menu > .main-menu-wrap .menu-ul {
        list-style-type: none;
        margin: 0;
        padding: 2.14286rem .71429rem; }
        .sidebar > .navbar-wrap > .menu > .main-menu-wrap .menu-ul .menu-header {
          font-weight: bold;
          color: #fff;
          display: block;
          font-size: .9em;
          letter-spacing: .8px;
          padding: 1.07rem .7rem;
          text-transform: uppercase;
          border-bottom: 1px solid #fff; }
        .sidebar > .navbar-wrap > .menu > .main-menu-wrap .menu-ul .menu-item {
          display: list-item;
          text-align: -webkit-match-parent;
          color: #fff;
          cursor: pointer;
          padding: .17857rem 0;
          margin: .35714rem 0;
          border-radius: 20px;
          position: relative; }
          .sidebar > .navbar-wrap > .menu > .main-menu-wrap .menu-ul .menu-item .sub-menu-wrapper {
            -webkit-transform: translateY(5px);
                    transform: translateY(5px);
            -webkit-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out; }
            .sidebar > .navbar-wrap > .menu > .main-menu-wrap .menu-ul .menu-item .sub-menu-wrapper > .sub-menu {
              background: RGBA(255, 255, 255, 0.07);
              border-radius: 10px;
              list-style-type: none;
              overflow: hidden;
              padding: .4rem .2rem;
              -webkit-transition: -webkit-transform .5s ease-in-out;
              transition: -webkit-transform .5s ease-in-out;
              transition: transform .5s ease-in-out;
              transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out; }
          .sidebar > .navbar-wrap > .menu > .main-menu-wrap .menu-ul .menu-item.active:not(.has-sub) .item-link {
            background-color: RGBA(255, 255, 255, 0.3); }
          .sidebar > .navbar-wrap > .menu > .main-menu-wrap .menu-ul .menu-item:hover .item-link > .link-icon {
            opacity: 1;
            color: RGB(var(--main-accent-color)); }
          .sidebar > .navbar-wrap > .menu > .main-menu-wrap .menu-ul .menu-item .sub-menu-wrapper {
            -webkit-transform: translateY(5px);
                    transform: translateY(5px);
            -webkit-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out; }
            .sidebar > .navbar-wrap > .menu > .main-menu-wrap .menu-ul .menu-item .sub-menu-wrapper > .sub-menu {
              background: RGBA(255, 255, 255, 0.07);
              border-radius: 10px;
              list-style-type: none;
              overflow: hidden;
              padding: .4rem .2rem;
              -webkit-transition: -webkit-transform .5s ease-in-out;
              transition: -webkit-transform .5s ease-in-out;
              transition: transform .5s ease-in-out;
              transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out; }
          .sidebar > .navbar-wrap > .menu > .main-menu-wrap .menu-ul .menu-item .item-link {
            align-items: center;
            border-radius: 500px;
            color: #fff;
            display: flex;
            height: 100%;
            justify-content: space-between;
            min-height: 2.14rem;
            padding: .4rem;
            position: relative;
            cursor: pointer;
            text-decoration: none;
            -webkit-transition: background .2s ease-in-out,color .2s ease-in-out,opacity .2s ease-in-out;
            transition: background .2s ease-in-out,color .2s ease-in-out,opacity .2s ease-in-out; }
            .sidebar > .navbar-wrap > .menu > .main-menu-wrap .menu-ul .menu-item .item-link > .link-text {
              flex: 2 1;
              font-size: 1.1em;
              line-height: 1.25;
              margin: 0 .35714rem;
              -webkit-transition: color .2s ease-in-out,opacity .2s ease-in-out;
              transition: color .2s ease-in-out,opacity .2s ease-in-out; }
            .sidebar > .navbar-wrap > .menu > .main-menu-wrap .menu-ul .menu-item .item-link > .link-icon {
              color: inherit;
              font-size: 1.2em;
              margin: 1px .7rem 0 .6rem;
              min-width: 17px;
              opacity: .3;
              padding: 0;
              -webkit-transition: color .2s ease-in-out,opacity .2s ease-in-out;
              transition: color .2s ease-in-out,opacity .2s ease-in-out; }

.header{
	background-color: rgb(var(--header-bg));
	height: 66px;
	position: relative;
	box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, .3);
	width: 100%;
	z-index: 999;
}

.header .header-wrap{
    align-items: center;
    background: inherit;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    min-height: inherit;
    padding: .71429rem 1rem;
}

.header-wrap .toggle svg{
	color: RGBA(var(--header-color));
}
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1; } }

@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1; } }

@-webkit-keyframes swing-in-top-bwd {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1; }
  100% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0; } }

@keyframes swing-in-top-bwd {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1; }
  100% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0; } }

.dropdown-wrapper {
  position: relative; }
  .dropdown-wrapper > .dropdown-trigger {
    cursor: pointer; }
  .dropdown-wrapper > .dropdown-content {
    position: absolute;
    z-index: 1000;
    float: left;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    will-change: transform;
    -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
            animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both; }
    .dropdown-wrapper > .dropdown-content.fading {
      -webkit-animation: swing-in-top-bwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
              animation: swing-in-top-bwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both; }

.dropdown-menu {
  z-index: 1000;
  display: flex;
  flex-direction: column;
  float: left;
  min-width: 10rem;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: RGB(var(--main-accent-text-color));
  text-align: left;
  list-style: none;
  background-color: RGB(var(--main-accent-color));
  background-clip: padding-box;
  border: 1px solid RGB(var(--main-accent-color-dark));
  border-radius: .25rem;
  box-shadow: 3px 5px 3px 2px rgba(0, 0, 0, 0.5); }
  .dropdown-menu > a {
    text-decoration: none;
    color: RGB(var(--main-accent-text-color)); }
  .dropdown-menu > .dropdown-item {
    display: block;
    width: 100%;
    padding: .75rem 1.5rem;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    cursor: pointer;
    font-weight: bold;
    -webkit-transition: background-color ease-in-out .4s;
    transition: background-color ease-in-out .4s; }
    .dropdown-menu > .dropdown-item.active, .dropdown-menu > .dropdown-item:hover {
      background-color: RGB(var(--main-accent-color-dark)); }
    .dropdown-menu > .dropdown-item:not(:last-child) {
      border-bottom: 1px solid RGB(var(--main-accent-color-dark)); }

.skeletonBlock{
	display: inline-block;
}
.main-content{
	height: 100%;
	width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    -webkit-transition: padding .2s ease-in-out 0s;
    transition: padding .2s ease-in-out 0s;
}
.error-screen {
  display: flex;
  background-color: RGB(var(--error-bg));
  height: 100%;
  width: 100%;
  background-image: url(/static/media/_logo.055c5a90.svg);
  background-repeat: no-repeat;
  background-position: 95% 2%;
  color: RGB(var(--error-color));
  padding-left: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; }
  .error-screen > h1 {
    font-size: 10rem;
    margin: 2rem 0; }
  .error-screen > .error-line {
    display: block;
    height: 2px;
    background-color: RGB(var(--error-color));
    width: 50%; }
  .error-screen > p {
    margin: 1rem 0;
    font-size: 2rem; }

.hero-wrapper {
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw; }

@-webkit-keyframes color-change {
  0% {
    background-color: rgba(0, 0, 0, var(--hero-dark-alpha)); }
  100% {
    background-color: rgba(var(--main-accent-color), 0.7); } }

@keyframes color-change {
  0% {
    background-color: rgba(0, 0, 0, var(--hero-dark-alpha)); }
  100% {
    background-color: rgba(var(--main-accent-color), 0.7); } }
  .hero-wrapper > .hero-image, .hero-wrapper > .hero-gradient {
    position: absolute; }
  .hero-wrapper > .hero-image, .hero-wrapper > .hero-gradient, .hero-wrapper > .hero-content {
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100vw;
    height: 100%; }
  .hero-wrapper > .hero-image {
    background-size: cover;
    z-index: 1;
    background-attachment: fixed; }
    @media (max-width: 768px) {
      .hero-wrapper > .hero-image {
        background-position: center; } }
  .hero-wrapper > .hero-gradient {
    z-index: 2;
    -webkit-animation: color-change 5s linear infinite alternate both;
            animation: color-change 5s linear infinite alternate both; }
  .hero-wrapper > .hero-image + .hero-gradient {
    mix-blend-mode: multiply; }
  .hero-wrapper > .hero-content {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center; }

.hero-card {
  align-items: stretch;
  background: RGBA(var(--hero-card-color), var(--hero-card-alpha));
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  margin-top: 2.9rem;
  padding: 6.4rem 7.1rem;
  width: 100%; }
  @media (max-width: 768px) {
    .hero-card {
      padding: 2.1rem;
      margin: 2.1rem auto .7rem; } }
  .hero-card > .hero-title {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    padding-left: 1.8rem;
    width: 100%; }
    @media (max-width: 767px) {
      .hero-card > .hero-title {
        padding: 0; } }
  .hero-card > .hero-body {
    width: 100%;
    position: relative; }

.form-card {
  align-items: stretch;
  background: RGB(var(--card-color));
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  padding: 1.4rem 2.1rem;
  width: 100%;
  border: 1px solid RGB(var(--form-card-border));
  margin: 1rem 0; }
  @media (max-width: 768px) {
    .form-card {
      padding: 2.1rem;
      margin: 2.1rem auto .7rem; } }
  .form-card .form-title {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.4rem;
    padding-bottom: 1.4rem;
    width: 100%;
    border-bottom: 1px solid RGB(var(--title-color)); }
    @media (max-width: 767px) {
      .form-card .form-title {
        padding: 0; } }
  .form-card > .form-body {
    width: 100%;
    position: relative; }

.wall-grid {
  display: flex;
  margin-left: -1.21rem;
  width: auto; }

.wall-column {
  padding-left: 1.21rem;
  background-clip: padding-box; }

@-webkit-keyframes bounce-in-bottom {
  0% {
    top: 500px;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0; }
  38% {
    top: 0;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1; }
  55% {
    top: 65px;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  72% {
    top: 0;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  81% {
    top: 28px;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  90% {
    top: 0;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  95% {
    top: 8px;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  100% {
    top: 0;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; } }

@keyframes bounce-in-bottom {
  0% {
    top: 500px;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0; }
  38% {
    top: 0;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1; }
  55% {
    top: 65px;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  72% {
    top: 0;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  81% {
    top: 28px;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  90% {
    top: 0;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  95% {
    top: 8px;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  100% {
    top: 0;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; } }

.project-update {
  margin-bottom: .75rem;
  display: inline-block;
  width: 100%;
  position: relative;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: RGB(var(--card-color));
  background-clip: border-box;
  border: 1px solid RGB(var(--card-border));
  border-radius: 10px;
  cursor: pointer; }
  .project-update.entering {
    -webkit-animation: bounce-in-bottom 1.1s both;
            animation: bounce-in-bottom 1.1s both; }
  .project-update > *::first-child {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px); }
  .project-update > .project-update-header {
    display: flex;
    border-bottom: 1px solid RGB(var(--card-border));
    padding: 5px;
    align-items: center; }
    .project-update > .project-update-header > .project-update-user-photo {
      height: 45px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: RGB(var(--card-media-bg));
      color: RGB(var(--card-accent));
      flex-shrink: 0; }
      .project-update > .project-update-header > .project-update-user-photo > img {
        max-width: 100%;
        max-height: 100%;
        padding: 5px; }
    .project-update > .project-update-header > .project-update-header-info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 0px 10px;
      overflow: hidden; }
      .project-update > .project-update-header > .project-update-header-info > * {
        white-space: nowrap;
        text-overflow: ellipsis; }
      .project-update > .project-update-header > .project-update-header-info > .project-update-project {
        font-size: large; }
      .project-update > .project-update-header > .project-update-header-info > .project-update-user {
        font-weight: bold; }
      .project-update > .project-update-header > .project-update-header-info > .project-update-created {
        font-size: smaller; }
  .project-update > .project-update-body {
    flex: 1 1 auto;
    padding: 1.25rem;
    padding: .3rem 1rem;
    border-top: 1px solid RGB(var(--card-border)); }
  .project-update > .project-update-media {
    background-color: RGB(var(--card-media-bg));
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden; }
    .project-update > .project-update-media:after {
      content: "";
      display: block;
      padding-bottom: 59.52%; }
    .project-update > .project-update-media > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .project-update > .project-update-media > .update-more {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: RGBA(var(--card-media-bg), 0.4);
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 6rem;
      color: #fff; }
      .project-update > .project-update-media > .update-more ::-moz-selection {
        background-color: transparent; }
      .project-update > .project-update-media > .update-more ::selection {
        background-color: transparent; }
    .project-update > .project-update-media .update-media {
      display: flex;
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
      overflow: hidden; }
      .project-update > .project-update-media .update-media > .update-image:not(.unique) {
        object-fit: cover !important; }
        .project-update > .project-update-media .update-media > .update-image:not(.unique).last {
          height: 100%;
          width: 100%; }
      .project-update > .project-update-media .update-media > .update-image.unique {
        object-fit: contain !important;
        margin: 0 auto; }
      .project-update > .project-update-media .update-media.even {
        flex-direction: row; }
        .project-update > .project-update-media .update-media.even.reverse {
          flex-direction: row-reverse; }
        .project-update > .project-update-media .update-media.even > .update-image:not(.last) {
          max-width: 59.52% !important; }
      .project-update > .project-update-media .update-media.odd {
        flex-direction: column; }
        .project-update > .project-update-media .update-media.odd.reverse {
          flex-direction: column-reverse; }
        .project-update > .project-update-media .update-media.odd > .update-image:not(.last) {
          max-height: 59.52% !important; }

@-webkit-keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1; }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(2px);
            filter: blur(2px);
    opacity: 0; } }

@keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1; }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(2px);
            filter: blur(2px);
    opacity: 0; } }

@-webkit-keyframes fade-out {
  0% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1; }
  100% {
    -webkit-filter: blur(2px);
            filter: blur(2px);
    opacity: 0; } }

@keyframes fade-out {
  0% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1; }
  100% {
    -webkit-filter: blur(2px);
            filter: blur(2px);
    opacity: 0; } }

@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(2px);
            filter: blur(2px);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1; } }

@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(2px);
            filter: blur(2px);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1; } }

@-webkit-keyframes fade-in {
  0% {
    -webkit-filter: blur(2px);
            filter: blur(2px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1; } }

@keyframes fade-in {
  0% {
    -webkit-filter: blur(2px);
            filter: blur(2px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1; } }

#modal-root {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  #modal-root .modal-fade {
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    -webkit-animation: fade-in 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
            animation: fade-in 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
    background-color: rgba(0, 0, 0, 0.5); }
    #modal-root .modal-fade.fading {
      -webkit-animation: fade-out 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) both !important;
              animation: fade-out 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) both !important; }
  #modal-root .modal-overlay {
    -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
            animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
    margin: 0 auto;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100vw;
    z-index: 2;
    position: relative;
    display: flex;
    max-height: 100vh; }
    #modal-root .modal-overlay .modal-content {
      align-items: stretch;
      background: RGB(var(--card-color));
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: auto;
      max-height: 100%;
      overflow-x: hidden; }
      #modal-root .modal-overlay .modal-content > *:not(.close-button) {
        margin: 0 !important;
        padding: 0 !important; }
      #modal-root .modal-overlay .modal-content > .close-button {
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 25px;
        right: 0px;
        background: RGB(var(--card-color));
        color: RGB(var(--text-color));
        border: 1px solid RGB(var(--text-color));
        cursor: pointer; }
    #modal-root .modal-overlay.fading {
      -webkit-animation: puff-out-center 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) both !important;
              animation: puff-out-center 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) both !important; }

