.form-title{
	color: RGB(var(--title-color));
    font-size: 1.8em;
    letter-spacing: .1px;
}

.form-holder{
	position: relative;
}

.form-item{
	color: RGB(var(--title-color));
    font-size: 14px;
    line-height: 1.5;
	padding-bottom : 8px;
	position: relative;
	
	&:after, &:before {
		display: table;
		content: "";
	}
	
	> .form-item-label{
	    display: block;
		margin: 0;
		padding: 0 0 8px;
		line-height: 1.5;
		white-space: normal;
		text-align: left;
		
		> label{
			color: RGB(var(--title-color));
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 0;
		}
	}
	
	> .form-item-error{
		color: rgb(237, 37, 61);
		clear: both;
		min-height: 22px;
		margin-top: -2px;
		font-size: 14px;
		line-height: 1.5;
	}
	
	> .form-item-field{
		position: relative;
	
		> .form-item-wrapper{
			box-sizing: border-box;
			margin: 0;
			padding: 0;
			color: RGB(var(--title-color));
			font-size: 14px;
			line-height: 1.5;
			list-style: none;
			position: relative;
			width: 100%;
			text-align: start;
			display: inline-block;
			
			> .file-dropper{
				position: relative;
				width: 100%;
				
				> input{
					display : none;
				}
				
				> .file-dropper-thumbnails{
					margin-top: 5px;
					margin-bottom: 5px;
					display: flex;
					flex-direction: row;
					position: relative;
					flex-wrap: wrap;
					width: 100%;
					z-index: 2;
					justify-content: space-between;
					align-items: baseline;
					padding: 5px;
					border-radius: 5px;
					border: 1px dashed RGBA(var(--input-field-border), .5);
					background-color: RGBA(var(--input-field-bg), .7);
					
					> .thumbnail{
						flex-basis: 23%;
						margin: 5px 0;
					}
					
					&:hover {
						background-color: RGBA(var(--input-field-bg), .9);
					}
				
				}
				
				> .file-dropper-disclaimers{				
					display: flex;
					flex-direction: column;
					padding: 0 10px;
					
					> .disclaimer{
						font-size: 12px;
						line-height: 12px;
					}
				}
				
				> .file-dropper-placeholder{
					margin-bottom: 5px;
					padding: 5px;
					min-height: 90px;
					border: 1px solid RGBA(var(--input-field-border), .3);
					background-color: RGBA(var(--input-field-bg), .7);
					cursor: pointer;
					border-radius: 10px;
					position: relative;
					display: flex;
					width: 100%;
					z-index: 1;
					align-items: center;
					justify-content: center;
					top: 0;
					left: 0;
					
					&.disabled{
						cursor: not-allowed;
						background-color: #ccc !important;
					}

					&.required{
						border-color: RGBA(237, 37, 61, .5);
					}
					
					&:hover {
						background-color: RGBA(var(--input-field-bg), .9);
						&.required{
							border-color: RGBA(237, 37, 61, .8);
						}
					}

					> .placeholder-text{
						color: RGB(var(--title-color));
						font-size: 16px;
						font-weight: bold;
					}
				}
			}
			
			> .switch-wrapper{
				display: flex;
				align-items: center;
			
				> .switch-label{				
					color: RGB(var(--title-color));
					font-size: 16px;
					font-weight: bold;
					margin-bottom: 0;
					margin-left: 5px;
					cursor: pointer;
					
					&.switch-label-positive{
						margin-left: 5px;
					}
					
					&.switch-label-negative{
						margin-right: 5px;
					}
					
				}
				
				> .switch{
					flex-grow : 1;
					background-color: RGBA(var(--input-field-bg), .7);
					border: 1px solid RGBA(var(--input-field-border), .3);
					height: 20px;
					padding: 0 5px;
					border-radius : 30px;
					/*flex: 0 0 60px;*/
					width:60px;
					max-width: 60px;
					position: relative;
					transition: background-color ease-in-out .1s;
					
					> .switch-indicator{
						height: 25px;
						width: 25px;
						display: block;
						position: absolute;						
						background-color: RGB(var(--input-field-bg));
						border: 1px solid RGBA(var(--input-field-border), .3);
						border-radius: 50%;
						cursor: pointer;
						top: -4px;
						left: 0px;
						transition: left ease-in-out .2s, background-color ease-in-out .1s;
						
						&:hover{
							background-color: RGB(var(--main-accent-color));
							border-color: rgba(var(--input-field-border), .5);
						}	
					}
					
					
					&.active{
						background-color: RGBA(var(--main-accent-color), .6);
						
						> .switch-indicator{
							left: calc(100% - 25px);
							background-color: RGB(var(--main-accent-color));
						}
						
					}
					
				}
				
				
				> input[type=hidden]:required:invalid ~ .switch{
					border-color: RGBA(237, 37, 61, .5);
					
					&:hover {
						border-color: RGBA(237, 37, 61, .8);
					}
				}
			
			}
			
			
			> .input-wrapper{
				display: flex;
				
				> .checkbox-label, > .radio-label{				
					color: RGB(var(--title-color));
					font-size: 16px;
					font-weight: bold;
					margin-bottom: 0;
					margin-left: 5px;
					cursor: pointer;
				}
				
				> input[type=checkbox]{
					border-radius: 3px;
				
					&:checked {
						&:after {
							content: '\2714';
							font-size: 14px;
							color: RGB(var(--main-accent-color));
							top: 0px;
							left: 4px;
						}
					}
				}

				> input[type=radio]{
					border-radius: 50%;
					
					&:checked {
						&:after {
							content: ' ';
							height: 11px;
							width: 11px;
							background-color: RGB(var(--main-accent-color));
							display: block;
							border-radius: 50%;
							top: 4px;
							left: 4px;
						}
					}
				}

				> input[type=checkbox], > input[type=radio]{
					appearance: none;
					background-color: RGBA(var(--input-field-bg), .7);
					border: 1px solid RGBA(var(--input-field-border), .3);
					padding: 9px;
					display: inline-block;
					position: relative;
					outline: none;
					height: 23px;
					width: 23px;
					cursor: pointer;
					
					&:required:invalid{
						border-color: RGBA(237, 37, 61, .5);
					}
					
					&:active, &:checked:active, &:hover {
						background-color: RGBA(var(--input-field-bg), .9);
						&:required:invalid{
							border-color: RGBA(237, 37, 61, .8);
						}
					}

					&:checked {
						background-color: RGBA(var(--input-field-bg), .9);
						border-color: RGB(var(--main-accent-color));
						color: RGB(var(--main-accent-color));
						
						&:after {
							position: absolute;
						}
					}
				}
			}
			
			
			
			> .form-item-prefix, > .form-item-suffix{
			    position: absolute;
				top: 50%;
				z-index: 2;
				color: RGB(var(--title-color));
				line-height: 0;
				transform: translateY(-50%);
			}
			
			> .form-item-prefix{
				left: 12px;
			}
			
			> .form-item-suffix{
				right: 12px;
			}
			
			> select{
				border-radius: 15px 15px 0 0;
				> option{
					background-color: RGB(var(--input-field-bg));
					border-color: RGBA(var(--input-field-border), .3);
					border-style: solid;
				}
				
				> option:hover,
				> option:focus,
				> option:active,
				> option:checked{
					color: #000;
					font-weight: bold;
					background-color: RGB(var(--main-accent-color));
				} 
			}
			
			/*input::selection, textarea::selection, select::selection {
				background-color: transparent;
			}*/
			
			> span.input{
				display: flex;
				align-content: center;
				justify-items: center;
				line-height: 40px;
				overflow: hidden;
			}
			
			> span.input, > textarea, > input:not([type=checkbox]):not([type=radio]), > select{
				box-shadow: none;
				border: 0px solid RGBA(var(--input-field-border), .3);
				background-color: RGBA(var(--input-field-bg), .7);
				height: 40px;
				color: RGB(var(--title-color));
				padding: 0 30px;
				position: relative;
				text-align: inherit;
				min-height: 100%;
				width: 100%;
				outline : none;
				background-size: 13px;
				background-repeat: no-repeat;
				background-position: calc(100% - 43px) center;
				
				&:required:invalid{
					background-image: url(../../Assets/required.png);
					border-color: RGBA(237, 37, 61, .5);
				}
				
				&:focus, &:hover{
					outline : none;
					border-color: RGBA(var(--input-field-border), .5);
					background-color: RGBA(var(--input-field-bg), .9);
					
					&:required:invalid{
						border-color: RGBA(255, 0, 0, .8);
					}
				}
			}
			
			> textarea{
				border-radius: 15px;
				resize: none;
				padding: 10px 30px;
				height: 120px;
			}
			
			> span.input, > input:not([type=checkbox]):not([type=radio]){
				border-radius: 30px;
			}
		
			textarea:disabled, input:disabled, select:disabled {
				cursor: not-allowed;
				background-color: #ccc !important;
			}
		
		}
	}
}