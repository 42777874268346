.header{
	background-color: rgb(var(--header-bg));
	height: 66px;
	position: relative;
	box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, .3);
	width: 100%;
	z-index: 999;
}

.header .header-wrap{
    align-items: center;
    background: inherit;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    min-height: inherit;
    padding: .71429rem 1rem;
}

.header-wrap .toggle svg{
	color: RGBA(var(--header-color));
}