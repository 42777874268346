@keyframes bounce-in-bottom {
  0% {
    top: 500px;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    top: 0;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    top: 65px;
    animation-timing-function: ease-in;
  }
  72% {
    top: 0;
    animation-timing-function: ease-out;
  }
  81% {
    top: 28px;
    animation-timing-function: ease-in;
  }
  90% {
    top: 0;
    animation-timing-function: ease-out;
  }
  95% {
    top: 8px;
    animation-timing-function: ease-in;
  }
  100% {
    top: 0;
    animation-timing-function: ease-out;
  }
}

.project-update{
	margin-bottom: .75rem;
    display: inline-block;
    width: 100%;
	position: relative;
	flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: RGB(var(--card-color));
    background-clip: border-box;
    border: 1px solid RGB(var(--card-border));
    border-radius: 10px;
	cursor: pointer;
	
	&.entering{
		animation: bounce-in-bottom 1.1s both;
	}
	
	> *::first-child{
	    border-top-left-radius: calc(.25rem - 1px);
		border-top-right-radius: calc(.25rem - 1px);
	}
	
	> .project-update-header{
		display: flex;
		border-bottom: 1px solid RGB(var(--card-border));
		padding: 5px;
		align-items: center;
		
		> .project-update-user-photo{
		    height: 45px;
			width: 45px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background-color: RGB(var(--card-media-bg));
			color: RGB(var(--card-accent));
			flex-shrink: 0;
			
			> img{				
				max-width: 100%;
				max-height: 100%;
				padding: 5px;
			}
			
		}
		
		> .project-update-header-info {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			padding: 0px 10px;
			overflow: hidden;
			
			> * {
				white-space : nowrap;
				text-overflow: ellipsis;
			}
			
			> .project-update-project {
				font-size: large;
			}
			
			> .project-update-user {
				font-weight: bold;
			}
			
			> .project-update-created {
				font-size: smaller;
			}
		}
	}
	
	> .project-update-body{
		flex: 1 1 auto;
		padding: 1.25rem;
		padding: .3rem 1rem;
		border-top: 1px solid RGB(var(--card-border));
	}
	
	> .project-update-media{
		background-color: RGB(var(--card-media-bg));
		display: flex;
		position: relative;
		height: 100%;
		width: 100%;
		overflow: hidden;

		
		&:after{
			content: "";
			display: block;
			padding-bottom: 59.52%;
		}

		> *{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		> .update-more{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: RGBA(var(--card-media-bg), .4);
			z-index: 5;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 6rem;
			color: #fff;
			
			::selection{
				background-color: transparent;
			}
			
		}
		
		.update-media{
			display: flex;
			max-height: 100%;
			max-width: 100%;
			height: 100%;
			width: 100%;
			overflow: hidden;
			
			> .update-image {
			
				&:not(.unique){
					object-fit: cover !important;
					
					&.last{
						height: 100%;
						width: 100%;
					}
				}
				
				&.unique{
					object-fit: contain !important;
					margin: 0 auto;
				}

			}
			
			
			&.even {
				flex-direction: row;
				
				&.reverse{
					flex-direction: row-reverse;
				}
				
				> .update-image:not(.last) {
					max-width: 59.52% !important;
				}
			}
			
			&.odd {
				flex-direction: column;
				
				&.reverse{
					flex-direction: column-reverse;
				}
				
				> .update-image:not(.last) {
					max-height: 59.52% !important;
				}
			}
		
		}
		
	}
	
}