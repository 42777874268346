@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

$theme-colors: (
  "primary": rgb(12, 172, 215),
  "danger": rgb(191, 31, 31),
  "neutral": rgb(31, 140, 191),
  "light": rgb(255, 255, 255),
  "dark": rgb(74, 80, 92)
);

@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/tables";
@import "react-redux-toastr/src/styles/index";


@import url('https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap');

a.btn{
	text-decoration: none;
}

button, .btn{
	cursor: pointer;
}

.btn{
	font-weight : bold;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: Nunito Sans,sans-serif;
	overflow: hidden;
    position: relative;
    width: 100%;
	background-color: #fff;
}

html{
	min-height: 100vh;
	background: #fff;
	font-size: 14px;
}

body, html, #root{
    height: 100%;
	line-height: 1.42857;
	font-feature-settings: "tnum","tnum";
	font-variant: tabular-nums;
	background-color: transparent;
	--main-accent-color: 12, 172, 215;
	--main-accent-color-dark: 30, 65, 117;
	--main-accent-text-color: 255, 255, 255;
}

.theme-light{
	--sidebar-dark-color : 45, 75, 114;
	--hero-dark-alpha : 0.3;
	--bg-color: 215, 241, 255;
	--input-field-bg: 230, 230, 231;
	--input-field-border: 0, 0, 0;
	--spinner-bg: 255, 255, 255;
	--spinner-border: 0, 0, 0;
	--title-color: 12, 172, 215;
	--text-color: 12, 172, 215;
	--form-card-border: 255, 255, 255;
	--card-border: 156, 150, 150;
	--card-color: 255, 255, 255;
	--card-media-bg: 12, 172, 215;
	--card-accent: 255, 255, 255;
	--hero-card-color: 255, 255, 255;
	--hero-card-alpha: .7;
	--error-bg: 95, 168, 211;
	--error-color: 255, 255, 255;
	--header-bg: 255, 255, 255;
	--header-color: 12, 172, 215;
	--pagination-active-bg: 83, 169, 241;
	--pagination-active-color: 0, 0, 0;
	--pagination-active-border: 57, 145, 218;
	--pagination-disabled-bg: 182, 215, 232;
	--pagination-disabled-color: 105, 117, 123;
	--pagination-disabled-border: 173, 211, 232;
	--pagination-bg: 212, 238, 252;
	--pagination-color: 1, 85, 156;
	--pagination-border: 173, 211, 232;
	--pagination-hover-bg: 220, 243, 255;
	--pagination-hover-color: 38, 127, 202;
	--pagination-hover-border: 83, 169, 241;
	--ghostload-color: 233, 233, 233;
	--ghostload-accent: 243, 243, 243;
	--scrollbar-bg: 255, 255, 255;
	--scrollbar-color: 0, 0, 0;
}

.theme-dark{
	--sidebar-dark-color : 0, 0, 0;
	--hero-dark-alpha : 0.8;
	--bg-color: 20, 21, 25;
	
	--input-field-bg: 0, 0, 0;
	--input-field-border: 120, 120, 120;
	--spinner-bg: 21, 19, 18;
	--spinner-border: 0, 0, 0;
	--title-color: 224, 214, 214;
	--text-color: 224, 214, 214;
	
	--form-card-border: 37, 31, 28;
	--card-border: 44, 50, 62;
	--card-color: 21, 19, 18;
	--card-media-bg: 44, 50, 62;
	--card-accent: 255, 255, 255;
	
	--hero-card-color: 74, 80, 92;
	--hero-card-alpha: 0.9;
	--error-bg: 35, 35, 35;
	--error-color: 255, 255, 255;
	
	--header-bg: 24, 24, 24;
	--header-color: 161, 171, 181;
	
	--pagination-active-bg: 1, 85, 156;
	--pagination-active-color: 255, 255, 255;
	--pagination-active-border: 0, 31, 58;
	--pagination-disabled-bg: 44, 46, 51;
	--pagination-disabled-color: 105, 117, 123;
	--pagination-disabled-border: 37, 33, 32;
	--pagination-bg: 25, 21, 20;
	--pagination-color: 1, 85, 156;
	--pagination-border: 35, 31, 30;
	--pagination-hover-bg: 45, 41, 40;
	--pagination-hover-color: 38, 127, 202;
	--pagination-hover-border: 55, 51, 50;
	
	--ghostload-color: 62, 50, 44;
	--ghostload-accent: 102, 90, 84;
	--scrollbar-bg: 60, 60, 60;
	--scrollbar-color: 120, 120, 120;
}

::-webkit-scrollbar {
    background: RGBA(var(--scrollbar-bg), .2);
    position: absolute;
    height: 5px;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: RGBA(var(--scrollbar-color), .6);
}
::-webkit-scrollbar-thumb:hover {
	background-color: RGBA(var(--scrollbar-color), .8);
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px RGBA(var(--scrollbar-color), .1);
}


@keyframes ghostLoading {
  0% { background-position  : -50vmin; }
  100% { background-position : 100vmin; }
}

.ghostLoad{
	animation: ghostLoading 2.5s infinite linear;
	background-color: RGB(var(--ghostload-color));
	background-image: linear-gradient(to right, RGB(var(--ghostload-color)) 0%, RGB(var(--ghostload-accent)) 20%, RGB(var(--ghostload-color)) 50%, RGBA(var(--ghostload-color)) 100%);
	background-repeat: no-repeat;
}

img::selection{
	background-color: transparent;
	color: transparent;
}