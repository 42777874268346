@keyframes puff-out-center {
	0% {
		transform: scale(1);
		filter: blur(0px);
		opacity: 1;
	}
	100% {
		transform: scale(2);
		filter: blur(2px);
		opacity: 0;
	}
}

@keyframes fade-out {
	0% {
		filter: blur(0px);
		opacity: 1;
	}
	100% {
		filter: blur(2px);
		opacity: 0;
	}
}

@keyframes puff-in-center {
	0% {
		transform: scale(2);
		filter: blur(2px);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		filter: blur(0px);
		opacity: 1;
	}
}

@keyframes fade-in {
	0% {
		filter: blur(2px);
		opacity: 0;
	}
	100% {
		filter: blur(0px);
		opacity: 1;
	}
}

#modal-root{
	z-index: 9999;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	width: 100%;
	height: 100%;
	overflow: hidden;
	
	
	.modal-fade{
		z-index: 1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		animation: fade-in 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
		background-color: rgba(0,0,0,.5);

		&.fading{
			animation: fade-out 0.5s cubic-bezier(0.470, 0.000, 0.745, 0.715) both !important;
		}
	}
	
	.modal-overlay{
		animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
		margin: 0 auto;
		width: max-content;
		max-width: 100vw;
		z-index: 2;
		position: relative;
		display: flex;
		max-height: 100vh;
		
		.modal-content{
			align-items: stretch;
			background: RGB(var(--card-color));
			border-radius: 10px;
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
			display: flex;
			flex-direction: column;
			width: 100%;
			overflow: auto;
			max-height: 100%;
			overflow-x: hidden;
			
			> *:not(.close-button){
				margin: 0 !important;
				padding: 0 !important;
			}
			
			> .close-button{
				border-radius: 50%;
				height: 30px;
				width: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				top: 25px;
				right: 0px;
				background: RGB(var(--card-color));
				color: RGB(var(--text-color));
				border: 1px solid RGB(var(--text-color));
				cursor: pointer;
			}
		}
		
		&.fading{
			animation: puff-out-center 0.5s cubic-bezier(0.470, 0.000, 0.745, 0.715) both !important;
		}
		
	}
	

	
	
}