.sidebar{
	background-image: linear-gradient(245deg, RGBA(var(--main-accent-color), .5), RGB(var(--sidebar-dark-color)) 65%);
    display: flex;
    justify-content: flex-end;
    height: 100%;
    left: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    width: 18.57143rem;
    will-change: transform,width;
    -webkit-overflow-scrolling: touch;
    z-index: 1000;
	
	@media screen and (max-width : 768px){
		transform: translateX(-18.57143rem);
		
		&.open{
			transform: translateX(0);
		}
	}
	
	> .navbar-wrap{
		align-items: flex-start;
		flex-direction: column;
		height: 100%;
		justify-content: flex-start;
		padding: 0;
		width: 18.57143rem;
		background: inherit;
		display: flex;
		margin: 0 auto;
		min-height: inherit;
		position: relative;
		
		> *{
			margin: 0;
			width: 100%;
		}
		
		> .logo {
			min-height: 60px;
			background: RGB(var(--header-bg));
			box-shadow: 0px 5px 10px 0 RGBA(0,0,0,.3);
			z-index: 99;
			display: block;
			height: 60px;
			position: relative;
			padding: .71429rem 1.42857rem;
			
			
			> .logo-wrap{
				align-items: center;
				color: inherit;
				display: flex;
				height: 100%;
				text-decoration: none;
				align-items: center;
				justify-content: space-between;

				> a{
					height: 100%;
				}
				
				> .close{
					height: 30px;
					width: 30px;
					display: flex;
					justify-content: center;
					align-content: center;
					align-items: center;
					cursor: pointer;
				}

				> .username{
					font-weight: bold;
					color: RGB(var(--header-color));
				}

				> svg{
					color: RGB(var(--header-color));
				}
				
			}
			
		}
		
		> .menu{
			display: block;
			letter-spacing: .2px;
			margin: 0;
			max-width: 100%;
			height: 100%;
			padding: 0;
			-webkit-transition: opacity .2s ease-in-out .2s;
			transition: opacity .2s ease-in-out .2s;
			overflow: auto;

			> .main-menu-wrap{
				.menu-ul {
					list-style-type: none;
					margin: 0;
					padding: 2.14286rem .71429rem;
					
					.menu-header{
						font-weight: bold;
						color: #fff;
						display: block;
						font-size: .9em;
						letter-spacing: .8px;
						padding: 1.07rem .7rem;
						text-transform: uppercase;
						border-bottom: 1px solid #fff;
					}			
					
					.menu-item{
						display: list-item;
						text-align: -webkit-match-parent;
						color: #fff;
						cursor: pointer;
						padding: .17857rem 0;
						margin: .35714rem 0;
						border-radius: 20px;
						position: relative;
						
						.sub-menu-wrapper{
							transform: translateY(5px);
							transition: all .5s ease-in-out;

							> .sub-menu{
								background: RGBA(255, 255, 255, 0.07);
								border-radius: 10px;
								list-style-type: none;
								overflow: hidden;
								padding: .4rem .2rem;
								transition: transform .5s ease-in-out;
							}
						}
						
						&.active:not(.has-sub){
							.item-link{
								background-color: RGBA(255,255,255,.3);
							}
						}

						&:hover{
							.item-link {
								> .link-icon{
									opacity: 1;
									color: RGB(var(--main-accent-color));
								}
							}
						}
						
						.sub-menu-wrapper{
							transform: translateY(5px);
							transition: all .5s ease-in-out;

							> .sub-menu{
								background: RGBA(255, 255, 255, 0.07);
								border-radius: 10px;
								list-style-type: none;
								overflow: hidden;
								padding: .4rem .2rem;
								transition: transform .5s ease-in-out;
							}
						}

						.item-link{
							align-items: center;
							border-radius: 500px;
							color: #fff;
							display: flex;
							height: 100%;
							justify-content: space-between;
							min-height: 2.14rem;
							padding: .4rem;
							position: relative;
							cursor: pointer;
							text-decoration: none;
							transition: background .2s ease-in-out,color .2s ease-in-out,opacity .2s ease-in-out;
							
							> .link-text {
								flex: 2 1 0;
								font-size: 1.1em;
								line-height: 1.25;
								margin: 0 .35714rem;
								transition: color .2s ease-in-out,opacity .2s ease-in-out;
							}
							
							> .link-icon {
								color: inherit;
								font-size: 1.2em;
								margin: 1px .7rem 0 .6rem;
								min-width: 17px;
								opacity: .3;
								padding: 0;
								transition: color .2s ease-in-out,opacity .2s ease-in-out;
							}
						}
					}
				}
			}
		}
		
	}
}